const topProductsList = [
  {
    sku: "0009",
    name: "Peach Goma",
    desc: "The latest iPhone from Apple.",
    category: "Printed",
    imgUrl: [
      "/images/printed/0009.jpg",
      "/images/printed/0009.1.jpg",
      "/images/printed/0009.2.jpg",
      "/images/printed/0009.3.jpg",
    ],
    price: 1390,
  },
  {
    sku: "0018",
    name: "Peach Goma",
    desc: "The latest Samsung Galaxy smartphone.",
    category: "Printed",
    imgUrl: [
      "/images/printed/0018.jpg",
      "/images/printed/0018.1.jpg",
      "/images/printed/0018.2.jpg",
      "/images/printed/0018.3.jpg",
    ],
    price: 1490,
  },
  {
    sku: "0011",
    name: "Jack Daniel's",
    desc: "",
    category: "Printed",
    imgUrl: ["/images/printed/0011.jpg"],
    price: 1690,
  },
  {
    sku: "0017",
    name: "Peach Goma",
    desc: "",
    category: "Printed",
    imgUrl: [
      "/images/printed/0017.jpg",
      "/images/printed/0017.1.jpg",
      "/images/printed/0017.2.jpg",
    ],
    price: 1390,
  },
  {
    sku: "0019",
    name: "Peach Goma",
    desc: "",
    category: "Printed",
    imgUrl: [
      "/images/printed/0019.jpg",
      "/images/printed/0019.1.jpg",
      "/images/printed/0019.2.jpg",
      "/images/printed/0019.3.jpg",
    ],
    price: 1390,
  },
  {
    sku: "0024",
    name: "White Heart",
    desc: "",
    category: "Printed",
    imgUrl: [
      "/images/printed/0024.jpg",
      "/images/printed/0024.1.jpg",
      "/images/printed/0024.2.jpg",
    ],
    price: 1490,
  },
  {
    sku: "0026",
    name: "Peach Goma",
    desc: "",
    category: "Printed",
    imgUrl: [
      "/images/printed/0026.jpg",
      "/images/printed/0026.1.jpg",
      "/images/printed/0026.2.jpg",
      "/images/printed/0026.3.jpg",
    ],
    price: 1590,
  },
  {
    sku: "0028",
    name: "White Flower",
    desc: "",
    category: "Printed",
    imgUrl: [
      "/images/printed/0028.jpg",
      "/images/printed/0028.1.jpg",
      "/images/printed/0028.2.jpg",
    ],
    price: 1390,
  },
  {
    sku: "0032",
    name: "Sun & Moon",
    desc: "",
    category: "Printed Couple",
    imgUrl: [
      "/images/printed/0032.jpg",
      "/images/printed/0032.1.jpg",
      "/images/printed/0032.2.jpg",
    ],
    price: 1590,
  },
  {
    sku: "0034",
    name: "BTS",
    desc: "",
    category: "Printed",
    imgUrl: [
      "/images/printed/0034.jpg",
      "/images/printed/0034.1.jpg",
      "/images/printed/0034.2.jpg",
    ],
    price: 1490,
  },
  {
    sku: "0035",
    name: "Sunflower",
    desc: "",
    category: "Printed",
    imgUrl: [
      "/images/printed/0035.jpg",
      "/images/printed/0035.1.jpg",
      "/images/printed/0035.2.jpg",
    ],
    price: 1590,
  },
  {
    sku: "0042",
    name: "King Cat",
    desc: "",
    category: "Printed",
    imgUrl: [
      "/images/printed/0042.jpg",
      "/images/printed/0042.1.jpg",
      "/images/printed/0042.2.jpg",
    ],
    price: 1990,
  },
];

export default topProductsList;
