import topProductsList from "./topProducts";

const allProductsList = [
  {
    sku: "0001",
    name: "Freedom",
    desc: "Freedom",
    category: "Printed",
    imgUrl: ["/images/printed/0001.jpg"],
    price: 1390,
  },
  {
    sku: "0002",
    name: "Lovely Butterflies",
    desc: "Lovely Butterflies pink color design",
    category: "Printed",
    imgUrl: ["/images/printed/0002.jpg"],
    price: 1490,
  },
  {
    sku: "0003",
    name: "Embarc Volunteers",
    desc: "Embarc Volunteers design. This is not a original one",
    category: "Printed",
    imgUrl: ["/images/printed/0003.jpg"],
    price: 1590,
  },
  {
    sku: "0004",
    name: "Batik Deco",
    desc: "Batik Decoration",
    category: "Printed",
    imgUrl: ["/images/printed/0004.jpg"],
    price: 1590,
  },
  {
    sku: "0005",
    name: "Adidas",
    desc: "Adidas",
    category: "Printed",
    imgUrl: ["/images/printed/0005.jpg"],
    price: 1290,
  },
  {
    sku: "0006",
    name: "Tommy Hilfiger",
    desc: "Tommy Hilfiger inline logo",
    category: "Printed",
    imgUrl: ["/images/printed/0006.jpg"],
    price: 1390,
  },
  {
    sku: "0007",
    name: "Hollister",
    desc: "Hollister",
    category: "Printed",
    imgUrl: ["/images/printed/0007.jpg"],
    price: 1390,
  },
  {
    sku: "0008",
    name: "Peach Goma",
    desc: "Peach Goma",
    category: "Printed",
    imgUrl: ["/images/printed/0008.jpg"],
    price: 1590,
  },
  {
    sku: "0010",
    name: "Tommy Hilfiger",
    desc: "Tommy Hilfiger Rectangular design with logo",
    category: "Printed",
    imgUrl: ["/images/printed/0010.jpg"],
    price: 1590,
  },
  {
    sku: "0012",
    name: "Born to Shoot",
    desc: "Photographer design",
    category: "Printed",
    imgUrl: ["/images/printed/0012.jpg"],
    price: 1790,
  },
  {
    sku: "0014",
    name: "Java",
    desc: "Java Powered | Programmer | Java developer design",
    category: "Printed",
    imgUrl: ["/images/printed/0014.jpg"],
    price: 1390,
  },
  {
    sku: "0015",
    name: "Good Vibes",
    desc: "Good vibes only | ladies design",
    category: "Printed",
    imgUrl: ["/images/printed/0015.jpg"],
    price: 1390,
  },
  {
    sku: "0020",
    name: "Kawasaki",
    desc: "Kawasaki | Biker t-shirt design",
    category: "Printed",
    imgUrl: ["/images/printed/0020.jpg"],
    price: 1490,
  },
  {
    sku: "0021",
    name: "Kawasaki",
    desc: "Kawasaki | Powering your potentials | Biker t-shirt design",
    category: "Printed",
    imgUrl: ["/images/printed/0021.jpg"],
    price: 1490,
  },
  {
    sku: "0022",
    name: "Lamborghini",
    desc: "Lamborghini",
    category: "Printed",
    imgUrl: ["/images/printed/0022.jpg"],
    price: 1590,
  },
  {
    sku: "0023",
    name: "Ducati",
    desc: "Ducati | Black and white colors | Biker t-shirt design",
    category: "Printed",
    imgUrl: ["/images/printed/0023.jpg", "/images/printed/0023.1.jpg"],
    price: 1490,
  },
  {
    sku: "0025",
    name: "Christmus",
    desc: "Christmus | Santa | Christmus season design",
    category: "Printed",
    imgUrl: ["/images/printed/0025.jpg", "/images/printed/0025.1.jpg"],
    price: 1290,
  },
  {
    sku: "0027",
    name: "Ducati",
    desc: "Ducati Corse | Biker t-shirt design",
    category: "Printed",
    imgUrl: ["/images/printed/0027.jpg", "/images/printed/0027.1.jpg"],
    price: 1490,
  },
  {
    sku: "0029",
    name: "Broken Heart",
    desc: "Broken Black Heart",
    category: "Printed",
    imgUrl: ["/images/printed/0029.jpg", "/images/printed/0029.1.jpg"],
    price: 1290,
  },
  {
    sku: "0030",
    name: "Couple Heart",
    desc: "Couple Heart | Outlined heart design for couples | Black and white colors",
    category: "Printed",
    imgUrl: ["/images/printed/0030.jpg", "/images/printed/0030.1.jpg"],
    price: 1190,
  },
  {
    sku: "0033",
    name: "Playboy",
    desc: "Playboy | Black and white colors",
    category: "Printed",
    imgUrl: [
      "/images/printed/0033.jpg",
      "/images/printed/0033.1.jpg",
      "/images/printed/0033.2.jpg",
      "/images/printed/0033.3.jpg",
    ],
    price: 1490,
  },
  {
    sku: "0037",
    name: "Dodge",
    desc: "Dodge | Car Lovers",
    category: "Printed",
    imgUrl: ["/images/printed/0037.jpg", "/images/printed/0037.1.jpg"],
    price: 1290,
  },
  {
    sku: "0040",
    name: "Kawasaki",
    desc: "Kawasaki | Bike Life",
    category: "Printed",
    imgUrl: [
      "/images/printed/0040.jpg",
      "/images/printed/0040.1.jpg",
      "/images/printed/0040.2.jpg",
    ],
    price: 1490,
  },
  {
    sku: "0041",
    name: "Artwork Yellow",
    desc: "Artwork Yellow | Graphics",
    category: "Printed",
    imgUrl: ["/images/printed/0041.jpg", "/images/printed/0041.1.jpg"],
    price: 1590,
  },
];

allProductsList.push(...topProductsList);

export default allProductsList;
